/* Module: S */
body {
    z-index: 2;
}



.header {
    padding: 0 pxToRem(40);
    @extend .clearfix;
}
.logo {
    float: left;
    text-align: left;
    margin: pxToRem(30) 0 pxToRem(30) 0;
    img {
        max-width: 245px;
    }
}
.mobile-navigation .logo {
    padding: pxToRem(30) 0 pxToRem(30) pxToRem(40);
    img {
        margin: 0;
    }
}
.mobile-navigation .header-mobile-options {
    display: none;
}
.mobile-navigation .site-links__list {
    li {
        display: inline-block;
        border-bottom: none;
        padding: 0 0 0 pxToRem(30);
        a {
            padding: pxToRem(20) 0;
        }
    }
}
.navigation__toggler.active {
    position: relative;
    &:after {
        content: "";
        width: 12px;
        height: 12px;
        left: 50%;
        margin-left: -12px;
        display: block;
        background: none;
        border-top: 12px solid transparent;
        border-right: 12px solid transparent;
        border-left: 12px solid transparent;
        border-bottom: 12px solid #122754;
        position: absolute;
        bottom: -32px;
    }
}

.header-mobile-options {
    background: none;
    position: absolute;
    right: 0;
    margin: pxToRem(30) 0 0 0;
    z-index: 6;
    padding: 0;
    right: pxToRem(40);
    svg path, svg g {
        fill: #000;
    }
    .search-item {
        display: block;
        svg path, svg g {
            fill: none;
            stroke: #000;
        }
    }
    .mobile-options__list-item {
        float: none;
        display: inline-block;
        a:not(.button) {
            padding: 0;
            width: 45px;
            height: 45px;
            margin: 0 21px 0 0;
            display: block;
            background: $light-grey;
            @include sq-border-radius(50%);
            text-align: center;
            span {
                display: none;
            }
            svg {
                width: 100%;
                height: 100%;
                padding: 10px;

                &.login-icon {
                    width: 35px;
                    padding: 0;
                }
            }
            &:hover,
            &:focus,
            &:active {
                background: $deep-orange;
            }
        }
    }
    .mobile-options__list-item:last-child {
        a {
            margin: 0;
        }
    }
}

.footer {
    padding: 0 pxToRem(40);
    @extend .clearfix; 
    .footer__list {
        margin: pxToRem(17) 0;
        float: left;
        width: 60%;
        li {
            width: auto;
            display: inline-block;
            text-align: center;
            margin: 0 pxToRem(20) 0 0;
        }
    } 
    .copyright {
        float: left;
        width: 40%;
        text-align: right;
        padding: pxToRem(13) 0;
        margin: pxToRem(17) 0;
    }
}


.image-banner {
    height: 404px;
}

.home-tabs__group {
    position: static;
    padding: 0;
}
.home-tabs__tab {
    padding: pxToRem(20);
    margin: -140px 0 0 0;
    //width: 50%;
    width: calc(100% / 3);
    position: absolute;
    height: 140px;
    top: 0;
    left: 0;
    // cursor: pointer;
}
.active {
    .home-tabs__tab {
        &:after {
            content: "";
            width: 12px;
            height: 12px;
            display: block;
            background: none;
            border-top: 12px solid transparent;
            border-right: 12px solid transparent;
            border-left: 12px solid transparent;
            border-bottom: 12px solid #fff;
            position: absolute;
            bottom: -1px;
            left: 50%;
            margin: 0 0 0 -6px;
        }
    }
}
.home-tabs__toggler {
    top: 12px;
    right: 20px;
}
.home-tabs__group + .home-tabs__group .home-tabs__tab {
    //right: 0;
    //left: auto;
    left: calc(100% / 3);
}
.home-tabs__group + .home-tabs__group + .home-tabs__group .home-tabs__tab {
    right: 0;
    left: auto;
}
.home-tabs__title {
    width: calc(100% - 45px);
    font-size: 21px;
    a {
        padding: 0;
    }
}
.home-tabs__bc {
    .home-tabs__tab {
        background: repeating-linear-gradient(
            60deg,
            #7db90a,
            #7db90a 38px,
            #73B736 38px,
            #73B736 76px
            );
            -webkit-box-shadow: inset 0px -50px 59px 0px #73B736;
            -moz-box-shadow: inset 0px -50px 59px 0px #73B736;
            box-shadow: inset 0px -50px 59px 0px #73B736;
    }
}
.home-tabs__cc {
    .home-tabs__tab {
        background: repeating-linear-gradient(
            120deg,
            #008cb2,
            #008cb2 38px,
            #0092ba 38px,
            #0092ba 76px
        );
        -webkit-box-shadow: inset 0px -50px 59px 0px rgba(0,140,178,1);
        -moz-box-shadow: inset 0px -50px 59px 0px rgba(0,140,178,1);
        box-shadow: inset 0px -50px 59px 0px rgba(0,140,178,1);
    }
}
.home-tabs__cs {
    .home-tabs__tab {
        background: repeating-linear-gradient(
        60deg,
        #d64016,
        #d64016 38px,
        #ea5329 38px,
        #ea5329 76px
        );
        -webkit-box-shadow: inset 0px -50px 59px 0px rgba(234,83,41,1);
        -moz-box-shadow: inset 0px -50px 59px 0px rgba(234,83,41,1);
        box-shadow: inset 0px -50px 59px 0px rgba(234,83,41,1);
    }
}

.home-tabs__content {
    background: #fff;
    margin: 0;
    padding: 0;
}
.active .home-tabs__content {
    padding: 13px 12px;
    margin: 0 0 9px 0;
}


.collapsed .home-tabs__item-title:after {
    background: #e5f1ce;
    @include sq-opacity(100);
}

.home-tabs__cc .collapsed .home-tabs__item-title:after {
    background: #CCE9F1;
    @include sq-opacity(100);
}
.home-tabs__cc .collapsed .home-tabs__item-title .icon-block svg path {
    fill: #00AAE3;
}

.home-tabs__cc .home-tabs__item-title .icon-block {
    background: #00AAE3;
}

.home-tabs__item {
    @include sq-box-shadow(0 0 4px 0 rgba(0, 0, 0, 0.29));
    margin: 0 0 10px 0;
}
.collapsed.home-tabs__item {
    @include sq-box-shadow(none);
}
.home-tabs__info-group {
    display: block;
    float: left;
    width: 50%;
}

.home-tabs__item-title {
    .icon-block {
        background: #7db90a;
        svg path {
            fill: #fff;
        }
    }   
}
.collapsed {
    .home-tabs__item-title .icon-block {
        background: #fff;
        svg path {
            fill: #8CC841;
        }
    }
}
.home-tabs__description {
    width: 100%;
    // font-size: pxToRem(14);
}  

.home-tabs__group.active {
    .home-tabs__description {
        display: block;
    }  
}
.home-tabs__group.inactive {
    .home-tabs__tab {
        @include sq-opacity(60);
    }
    &:hover .home-tabs__tab {
        @include sq-opacity(100);
    }
}
.home-tabs__bc .home-tabs__item-title a {
    color: $dark-lime-green;
}
.home-tabs__cc .home-tabs__item-title a {
    color: $water-blue;
}
.home-tabs__cc .collapsed .home-tabs__item-title a,
.home-tabs__bc .collapsed .home-tabs__item-title a {
    color: #0f1b42;
}

.home-tabs__default .home-tabs__image {
    display: block;
    //width: 50%;
    width: calc(100% / 3);
    float: left;
}
.ql__news {
    margin: 25px 1rem;
    padding: 42px 30px 42px 30px;
    .ql__news-pagination {
        top: 38px;
    }
    .ql__news-item {
        padding: 3px 0;
    }
    @include sq-flex-box();
    .ql__news-title {
        font-size: pxToRem(19);
        font-weight: bold;
        margin: 0 20px 0 0;
        padding: 3px 0 0;
    }
    .ql__news-carousel {
        width: calc(80% - 20px);
        padding-left: 20px;
        border-left: 1px solid #d6d6d6;
    }
}

.landing-grid {
    @include sq-flex-box();
    flex-flow: row wrap;
    padding: 25px 1rem;
}
.landing-grid__item {
    margin: 0 10px 20px 10px;
    /*flex: calc(50% - 20px);*/
    width: calc(50% - 20px);
}

.tab-navigation {
    display: block;
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
}
.tab-navigation-list {
    list-style: none;
    margin: 0;
    padding: 0;
    @include sq-flex-box();
}
.tab-navigation-item {
    display: block;
    margin: 0 5px 0 0;
    min-width: 150px;
}
.tab-navigation-link {
    display: block;
    background: rgba(246, 246, 246, 0.7);
    padding: 18px 5px 21px;
    text-align: center;
    color: #011c43;
    text-transform: uppercase;
    font-size: pxToRem(18);
    border-top: 4px solid transparent;
    &:hover,
    &:focus,
    &.active {
        background: #f5f4f5;
        border-top-color: $deep-orange;
    }
}
.header .mobile-navigation .search {
    display: none;
}
.header .search {
    width: 0;
    height: 0;
    margin: 0;
    overflow: hidden;
    visibility: hidden;
    display: block;
    @include sq-transition(width 300ms);
    &.active {
        padding: 0;
        position: absolute;
        right: 175px;
        top: 30px;
        width: 280px;
        height: auto;
        overflow: visible;
        visibility: visible;
        z-index: 9;
        .search-form {
            position: relative;
            .search-inner {
                @include sq-flex-box();
            }
            &:after {
                content: "";
                width: 100%;
                height: 100%;
                background: $light-grey;
                @include sq-border-radius(50px);
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                position: absolute;
                display: block;
                z-index: -1;
                pointer-events: none;
            }
        }
        svg g {
            stroke: #122754;
        }
        .search-input {
            border: none;
            color: #555;
            background: none;
            padding: 12px 0 12px 20px;
            margin: 0;
            width: calc(100% - 25px);
            font-size: pxToRem(16);
            &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
                color:    #555;
            }
            &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
                color:    #555;
                opacity:  1;
            }
            &::-moz-placeholder { /* Mozilla Firefox 19+ */
                color:    #555;
                opacity:  1;
            }
            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color:    #555;
            }
            &::-ms-input-placeholder { /* Microsoft Edge */
                color:    #555;
            }
            &::placeholder { /* Most modern browsers support this now. */
                color:    #555;
            }
        }
        .search-submit {
            border: none;
            background: none;
            margin: 0;
            padding: 0 10px;
        }
    }
}

.search-form {
    .tt-triangle {
        content: "";
        width: 15px;
        height: 15px;
        left: 60%;
        display: block;
        background: none;
        border-top: 15px solid transparent;
        border-right: 15px solid transparent;
        border-left: 15px solid transparent;
        border-bottom: 15px solid $dark-slate-blue;
        position: absolute;
        top: -30px;
    }
    .tt-menu {
            height: 455px;
            padding: 40px 25px 25px 25px;
            left: auto;
            right: -223px;
            
            .tt-dataset {
                float: left;
                width: 33%;
                margin-bottom: 0;
            }
    }
} 

.notifications__item {
    padding: 0;
}
.notifications__content {
    padding: 16px 185px 16px 70px;
}
.notifications-pagination {
    position: absolute;
    top: 10px;
    right: 30px;
    left: auto;
}
.notifications__close-item {
    top: 17px;
    bottom: auto;
}
.notifications__icon {
    top: 15px;
}


body.theme_bc {
    /* $dark-lime-green */
    .navigation .navigation__lvl-1 > li.current > a,
    .i-want-to__title,
    .i-want-to__title,
    .main .main__content .intro-text
    {
        color: $dark-lime-green;
    }
    .tab-navigation-link:hover, 
    .tab-navigation-link:focus, 
    .tab-navigation-link.active {
        border-top-color: $dark-lime-green;
    }
    .navigation__sub-list-item.current > .aside_navigation__link:after {
        border-left-color: $dark-lime-green;
    }
    .i-want-to {
        @include ql-bg-stripes('green');
    }
    .i-want-to__list {
        list-style-image: url(mysource_files/green-chevron.svg);
    }
    .landing-grid__link .ql__icon {
        border-color: $dark-lime-green;
    }
    .landing-grid__link .ql__icon svg path, 
    .landing-grid__link .ql__icon svg g {
        fill: $dark-lime-green;
    }
    .landing-grid__link:hover .ql__icon svg path, 
    .landing-grid__link:hover .ql__icon svg g, 
    .landing-grid__link:active .ql__icon svg path, 
    .landing-grid__link:active .ql__icon svg g {
        fill: #fff;
    }
    .landing-grid__link:hover .ql__icon, 
    .landing-grid__link:active .ql__icon,
    .aside_navigation__link:hover:before, 
    .aside_navigation__link.focus:before, 
    .active > .aside_navigation__link:before, 
    .navigation_has-subs.active > ul:before {
        background: $dark-lime-green;
    }
}

body.theme_cc {
    /* $water-blue */
    .navigation .navigation__lvl-1 > li.current > a,
    .i-want-to__title,
    .i-want-to__title,
    .main .main__content .intro-text
    {
        color: $water-blue;
    }
    .tab-navigation-link:hover, .tab-navigation-link:focus, .tab-navigation-link.active {
        border-top-color: $water-blue;
    }
    .navigation__sub-list-item.current > .aside_navigation__link:after {
        border-left-color: $water-blue;
    }
    .i-want-to {
        @include ql-bg-stripes('blue');
    }
    .i-want-to__list {
        list-style-image: url(mysource_files/blue-chevron.svg);
    }
    .landing-grid__link .ql__icon {
        border-color: $water-blue;
    }
    .landing-grid__link .ql__icon svg path, 
    .landing-grid__link .ql__icon svg g {
        fill: $water-blue;
    }
    .landing-grid__link:hover .ql__icon svg path, 
    .landing-grid__link:hover .ql__icon svg g, 
    .landing-grid__link:active .ql__icon svg path, 
    .landing-grid__link:active .ql__icon svg g {
        fill: #fff;
    }
    .landing-grid__link:hover .ql__icon, 
    .landing-grid__link:active .ql__icon,
    .aside_navigation__link:hover:before, 
    .aside_navigation__link.focus:before, 
    .active > .aside_navigation__link:before, 
    .navigation_has-subs.active > ul:before {
        background: $water-blue;
    }
}


body .sq-form {
    div.sq-form-question {
        display: block;
        width: calc(100% - 10px);
        float: left;
        clear: both;
    }
    > .sq-form-section ~ div.sq-form-question {
        float: none;
    }
    div[class*="form-col-"]{
        margin-right: 10px;
        float: left;
        clear: none;
    }
    div.form-col-2 {
        width: calc(50% - 10px);
    }
    div.form-col-3 {
        width: calc(33.33% - 10px);
    }
    div.form-col-4 {
        width: calc(25% - 10px);
    }
    div.form-col-5 {
        width: calc(20% - 10px);
    }
    div.form-col-6 {
        width: calc(100% / 6 - 10px);
    }
    div.form-col-7 {
        width: calc(100% / 7 - 10px);
    }
    div.form-col-8 {
        width: calc(100% / 8 - 10px);
    }
    div.form-col-9 {
        width: calc(100% / 9 - 10px);
    }
    div.form-col-10 {
        width: calc(10% - 10px);
    }
    div.form-col-11 {
        width: calc(100% / 11 - 10px);
    }
    div.form-col-12 {
        width: calc(100% / 12 - 10px);
    }
    > div {
        overflow: hidden;
    }
    .form-clear-row {
        display: block;
        width: 100%;
        clear: both;
    }
    input.form-list-block {
        clear: left;
        margin-bottom: 20px;
    }
}


#levy-calculator_price-table {
    tbody {
        td {
            display: table-cell;
            width: auto;
            clear: none;
            padding:  1rem;
        }
    }
}

.page_content-i-want-to {
    float: right;
    width: 33%;
    display: block;
    .landing-grid__item {
        width: 100%;
    }
}

.image-banner__info {
    top: 70px;
}

.login-button {
    &__wrapper {
        // margin: pxToRem(8) 0;
    }

    // font-size: 15px;
}
/* Module: S */
body {
    z-index: 3;
}
.contain-width {
    max-width: pxToRem(1200);
    margin-left: auto;
    margin-right: auto;
    position: relative;
    @extend .clearfix;
}

#page-wrapper {
    overflow-x: hidden;
}
.header {
    padding: 0;
}
.site-links {
    display: block;
    a {
        display: block;
        padding: pxToRem(10) pxToRem(12);
        color: $dark-grey;
        position: relative;
        &:after {
            content: "";
            display: block;
            width: 1px;
            height: auto;
            right: 0;
            top: 10px;
            bottom: 10px;
            background: #000;
            @include sq-opacity(20);
            position: absolute;
        }
    }
    li:last-child {
        a {
            &:after {
                display: none;
            } 
        }
    }

    .login-item {
        a.login__toggler {
            svg {
                display: inline-block;
                width: 22px;
                height: 100%;
                fill: black;
                vertical-align: bottom;
            }

            span {
                text-transform: uppercase;
                display: inline-block;
            }
        }
    }
}
.logo {
    margin: pxToRem(30) 0 pxToRem(33);
    //width: 30%;
    max-width: 280px;
    img {
        max-width: 100%;
    }
}
.mobile-navigation {
    display: none;
}
// min wodth 64em and max-wid 67em
@media (max-width: 67em) {
    body .navigation {
        .navigation__lvl-1 > li {
            & > a {
                font-size: pxToRem(16);
            }
        }
    }
}


body .navigation {
    .navigation__lvl-1 > li {
        .navigation__link-wrapper > a.navigation__link {
            @media (min-width: 1200px) {
                font-size: pxToRem(18);
            }
        }
    }
}

.navigation {
    display: block;
    font-size: pxToRem(19);
    float: right;
    width: calc(100% - 280px);

    .navigation__lvl-1 > li > .navigation__link-wrapper > a.navigation__link {
        line-height: 45px;
        display: block;
        position: relative;
        padding: 0 pxToRem(8) pxToRem(40);

        @media (min-width: 1024px) and (max-width: 1099px) {
            font-size: pxToRem(14);
        }

        font-size: pxToRem(16);

        &:after {
            content: "";
            display: block;
            width: 1px;
            height: 24px;
            left: 0;
            top: 10px;
            background: #000;
            @include sq-opacity(20);
            position: absolute;
        }
        &:hover, &:focus {
            &:before {
                // content: "";
                // width: 12px;
                // height: 12px;
                // left: 50%;
                // margin-left: -6px;
                // bottom: -1px;
                // display: block;
                // background: none;
                // border-top: 12px solid transparent;
                // border-right: 12px solid transparent;
                // border-left: 12px solid transparent;
                // border-bottom: 12px solid #002855;
                // position: absolute;
            }
        }
    }
    .navigation__lvl-1 > li.navigation__list-item-home > a.navigation__link {
        &:hover, &:focus {
            &:before {
                display: none;
            }
        }
    }
    li:last-child,
    li:first-child {
        a {
            &:after {
                display: none;
            } 
        }
    }
    .navigation__list {
        float: right;
        margin: 33px 0 0 0;
    }
    .navigation__lvl-2 {
        height: 0;
        width: 0;
        overflow: hidden;
        visibility: hidden;
        padding: 0;
        position: absolute;
        background: none;
        left: 0;
        right: 0;
        top: 100%;
        opacity: 0;
        margin: 0;
        padding: 0;
        list-style: none;
        /*@include sq-transition(height 300ms, opacity 300ms);*/
        -webkit-columns: auto 3; /* Chrome, Safari, Opera */
        -moz-columns: auto 3; /* Firefox */
        columns: auto 3;
        
        > .navigation__list-item {
            margin: 0;
            padding: 0;
            list-style: none;
            -webkit-column-break-inside: avoid;
            page-break-inside: avoid;
            break-inside: avoid;
            > .navigation__link {
                color: $tomato;
            }
        }
        &.active {
            min-height: 455px;
            height: auto;
            width: 100%;
            overflow: visible;
            visibility: visible;
            padding: 40px 0 0 0;
            opacity: 1;
            display: block;
            &:before {
                content: "";
                display: block;
                position: absolute;
                background: #002855;
                z-index: -2;
                height: 455px;
                width: auto;
                left: -1000px;
                right: -1000px;
                top: 0;
            }
            &:after {
                content: "";
                height: 150px;
                width: auto;
                left: -1000px;
                right: -1000px;
                bottom: 0;
                display: block;
                position: absolute;
                z-index: -1;
                background: repeating-linear-gradient(
                60deg,
                #002855,
                #002855 38px,
                #0f3564 38px,
                #0f3564 76px
                );
                -webkit-box-shadow: inset 0 82px 82px #002855;
                -moz-box-shadow: inset 0 82px 82px #002855;
                box-shadow: inset 0 82px 82px #002855;
            }
        }
        li {
            float: none;
        }
       
        > li > a {
            font-size: pxToRem(19);
            font-weight: bold;
            text-transform: uppercase;
            margin: 0 0 20px 13px;
            display: block;
        }
        a {
            color: #fff;
        }
    }.theme__bc .navigation__lvl-2 > .navigation__list-item > a {
        color: $bc-green;
    }
    .theme__cc .navigation__lvl-2 > .navigation__list-item > a {
        color: $cc-blue;
    }
    .navigation__lvl-3 {
        float: none;
        margin: 0 0 20px 0;
        padding: 0;
        list-style: none;
        a {
            font-weight: normal;
            font-size: pxToRem(16);
            padding: 8px 13px;
            display: block;
            &:hover, &:focus {
                background: #2b4b70;
            }
        }
    }
    .single_level-nav {
        position: relative;
        .navigation__lvl-2.active {
            width: 350px;
            right: 0;
            left: auto;
            
            -webkit-columns: 100% 1; /* Chrome, Safari, Opera */
            -moz-columns: 100% 1; /* Firefox */
            columns: 350px 1;
            li {
                display: block;
                width: 100%;
                clear: both;
                a {
                    color: #fff;
                    font-size: 16px;
                    text-transform: initial;
                    margin: 0 12px;
                    padding: 10px 20px;
                    font-weight: normal;
                    &:hover, &:focus {
                        background: #2b4b70;
                    }
                }
            }
            
            &:before {
                left: 0;
                right: 0;
            }
            &:after {
                left: 0;
                right: 0;
            }
        }
    }

    &.desktop-nav {
        .navigation__list-item {
            .navigation__link-wrapper {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: baseline;
                position: relative;
                padding-right: 12px;
    
                &.active {
                    &:before {
                        content: "";
                        width: 12px;
                        height: 12px;
                        left: 50%;
                        margin-left: -6px;
                        bottom: -1px;
                        display: block;
                        background: none;
                        border-top: 12px solid transparent;
                        border-right: 12px solid transparent;
                        border-left: 12px solid transparent;
                        border-bottom: 12px solid #002855;
                        position: absolute;
                    }
                }
    
                .navigation__link-toggler {
                    display: block;
                    -webkit-transform: rotate(90deg);
                    -ms-transform: rotate(90deg);
                    transform: rotate(90deg);
    
                    svg {
                        display: inline-block;

                        @media (min-width: 1024px) and (max-width: 1099px) {
                            width: 12px;
                            height: auto;
                        }

                        path {
                            fill: #001d45;
                        }
                    }
    
                    &.active {
                        -webkit-transform: rotate(-90deg);
                        -ms-transform: rotate(-90deg);
                        transform: rotate(-90deg);
                    }
                }
            }
            &.single_level-nav {
                .navigation__link-wrapper {
                    padding-right: 0;

                    .navigation__link-toggler {
                        display: none;
                    }
                }
            }

            &:first-child {
                a.navigation__link{
                    &:after {
                        display: none;
                    } 
                }
            }
        }
    }
}


.header-mobile-options {
    display: none;

    .mobile-options__list-item {
        a:not(.button) {
            @media (min-width: 1024px) {
                width: 40px;
                height: 40px;
            }
        }
    }
}
.search__toggler .header-mobile-options {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    right: 0;
    a:not(.button) {
        margin: 0;
    }
}
.header .search {
    width: 0;
    height: 0;
    margin: 0;
    overflow: hidden;
    visibility: hidden;
    display: block;
    @include sq-transition(width 300ms);
    &.active {
        padding: 13px 0 34px;
        position: absolute;
        right: 0;
        top: 18px;
        width: 65%;
        height: auto;
        overflow: visible;
        visibility: visible;
        z-index: 9;
        .search-form {
            position: relative;
            .search-inner {
                @include sq-flex-box();
            }
            &:after {
                content: "";
                width: 100%;
                height: 100%;
                background: $light-grey;
                @include sq-border-radius(50px);
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                position: absolute;
                display: block;
                z-index: -1;
                pointer-events: none;
            }
        }
        svg g {
            stroke: #122754;
        }
        .search-input {
            border: none;
            color: #555;
            background: none;
            padding: 16px 20px 16px 28px;
            margin: 0;
            width: calc(100% - 45px);
            font-size: pxToRem(16);
            &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
                color:    #555;
            }
            &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
                color:    #555;
                opacity:  1;
            }
            &::-moz-placeholder { /* Mozilla Firefox 19+ */
                color:    #555;
                opacity:  1;
            }
            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color:    #555;
            }
            &::-ms-input-placeholder { /* Microsoft Edge */
                color:    #555;
            }
            &::placeholder { /* Most modern browsers support this now. */
                color:    #555;
            }
        }
        .search-submit {
            border: none;
            background: none;
            margin: 0;
            padding: 0 22px;
        }
    }
}

.navigation .navigation__lvl-1 > li > .navigation__link-wrapper {
    > a.navigation__link {
        color: #001d45; 
    }
    &.current > a {
        color: $tomato;
    }
}

.home-tabs__tab {
    // margin: -170px 0 0 0;
    // height: 170px;
}

.home-tabs,
.ql__news {
    @extend .contain-width;
}
.ql__news {
    @include sq-flex-box();
    .ql__news-carousel {
        width: calc(80% - 20px);
        margin-left: 20px;
    }
}
.home-tabs__title {
    font-size: 24px;
}

.home-tabs__description {
    // font-size: pxToRem(15);
}
.home-tabs__item-title .icon-block {
    display: none;
}
.home-tabs__content {
    @include sq-flex-box();

}
.home-tabs__item {
    @include sq-box-shadow(none);
    flex-grow: 1;
    flex-basis: 50%;
    position: relative;
    margin: 0;
    &:after {
        content: "";
        width: 1px;
        height: auto;
        top: 20px;
        bottom: 0;
        left: 0;
        background: #f2f2f2;
        display: block;
        position: absolute;
    }
    .home-tabs__info-group {
        width: 100%;
        float: none;
        margin: 0;
    }
    &:first-of-type {
        &:after {
            display: none;
        }
    }
}
.home-tabs__item-info {
    margin: 0;
    padding-top: 0;
}

.home-tabs__info-group + .button {
    margin: 1rem 0 0 0;
}
.home-tabs__item-title {
    margin: 0;
}
.image-banner {
    height: 435px;
}
.image-banner__description {
    font-size: pxToRem(35);
    width: 100%;
    padding: 0;
}
.image-banner__info {
    position: relative;
    z-index: 2;
    top: 70px;
    width: 100%;
    text-align: center;
    color: #fff;
    max-width: 75rem;
    margin: 0 auto;
    .image-banner__description {
        margin: 0 auto;
    }
    .image-banner__info:before {
        margin-left: -52px;
    }
}
.image-banner__bc {
    .image-banner__info {
        position: relative;
        z-index: 2;
        margin: 0 auto;
        top: 70px;
        left: 0;
        width: 100%;
        max-width: 75rem;
        text-align: left;
        color: #fff;
        &:before {
            left: 0;
            margin: 0;
        }
        .image-banner__description {
            width: 450px;
            padding: 0;
            margin: 0;
            font-size: pxToRem(27);
        }
    }
}
.image-banner__cc {
    .image-banner__info {
        position: relative;
        z-index: 2;
        margin: 0 auto;
        top: 70px;
        left: 0;
        width: 100%;
        max-width: 75rem;
        text-align: right;
        color: #fff;
        &:before {
            left: auto;
            right: 0;
        }
        .image-banner__description {
            width: 450px;
            float: right;
            padding: 0;
            font-size: pxToRem(27);
        }
        .image-banner__cta {
            display: block;
            clear: both;
        }
    }
}
.inside-image-banner {
    height: 300px;
}

.main {
    display: block;
    margin-top: pxToRem(87);
    margin-bottom: pxToRem(87);
}
.main__aside {
    position: relative;
    height: 100%;
    top: auto;
    background: none;
    bottom: auto;
    z-index: 1;
    left: auto;
}
.aside__navigation__mobile-toggler {
    display: none !important;
}
body.home .main,
body.landing_tabs .main {
    margin: 0;
}
body.landing_notabs .main {
    margin-bottom: 0;
}


.inside-image-banner {
    .image-banner__info {
        @extend .contain-width;
        text-align: left;
        top: 75px;
        &:before {
            top: -20px;
            left: 0;
            margin: 0;
        }
    }
    h1 {
        font-size: pxToRem(37);
        padding: 0 375px 0 0;
    }
    .image-banner__description {
        position: absolute;
        right: 0;
        width: 350px;
        padding: 0;
        font-size: 22px;
        text-align: right;
        top: -15px;
    }
}

.landing-grid {
    padding: 25px 0;
}
.landing-grid__item {
    /*flex: calc(33% - 20px);*/
    width: calc(33% - 20px);
}
.tab-navigation-item {
    min-width: 180px;
}


body.theme_bc {
    /* $dark-lime-green */
    .navigation .navigation__lvl-1 > li.current > a,
    .i-want-to__title,
    .i-want-to__title,
    .main .main__content .intro-text
    {
        color: $dark-lime-green;
    }
    .tab-navigation-link:hover, 
    .tab-navigation-link:focus, 
    .tab-navigation-link.active {
        border-top-color: $dark-lime-green;
    }
    .navigation__sub-list-item.current > .aside_navigation__link:after {
        border-left-color: $dark-lime-green;
    }
    .i-want-to {
        @include ql-bg-stripes('green');
    }
    .i-want-to__list {
        list-style-image: url(mysource_files/green-chevron.svg);
    }
    .landing-grid__link .ql__icon {
        border-color: $dark-lime-green;
    }
    .landing-grid__link .ql__icon svg path, 
    .landing-grid__link .ql__icon svg g {
        fill: $dark-lime-green;
    }
    .landing-grid__link:hover .ql__icon svg path, 
    .landing-grid__link:hover .ql__icon svg g, 
    .landing-grid__link:active .ql__icon svg path, 
    .landing-grid__link:active .ql__icon svg g {
        fill: #fff;
    }
    .landing-grid__link:hover .ql__icon, 
    .landing-grid__link:active .ql__icon,
    .aside_navigation__link:hover:before, 
    .aside_navigation__link.focus:before, 
    .active > .aside_navigation__link:before, 
    .navigation_has-subs.active > ul:before {
        background: $dark-lime-green;
    }
}

body.theme_cc {
    /* $water-blue */
    .navigation .navigation__lvl-1 > li.current > a,
    .i-want-to__title,
    .i-want-to__title,
    .main .main__content .intro-text
    {
        color: $water-blue;
    }
    .tab-navigation-link:hover, .tab-navigation-link:focus, .tab-navigation-link.active {
        border-top-color: $water-blue;
    }
    .navigation__sub-list-item.current > .aside_navigation__link:after {
        border-left-color: $water-blue;
    }
    .i-want-to {
        @include ql-bg-stripes('blue');
    }
    .i-want-to__list {
        list-style-image: url(mysource_files/blue-chevron.svg);
    }
    .landing-grid__link .ql__icon {
        border-color: $water-blue;
    }
    .landing-grid__link .ql__icon svg path, 
    .landing-grid__link .ql__icon svg g {
        fill: $water-blue;
    }
    .landing-grid__link:hover .ql__icon svg path, 
    .landing-grid__link:hover .ql__icon svg g, 
    .landing-grid__link:active .ql__icon svg path, 
    .landing-grid__link:active .ql__icon svg g {
        fill: #fff;
    }
    .landing-grid__link:hover .ql__icon, 
    .landing-grid__link:active .ql__icon,
    .left-nav-border:before, 
    .aside_navigation__link:hover:before, 
    .aside_navigation__link.focus:before, 
    .active > .aside_navigation__link:before, 
    .navigation_has-subs.active > ul:before {
        background: $water-blue;
    }
}


.search-form {
    .tt-triangle {
        left: 50%;
        margin-left: -15px;
    }
    .tt-menu {
            width: auto;
            padding: 40px 0 0 0;
            left: -100px;
            right: -80px;
            top: 65px;
            padding: 45px 45px 45px 45px;
            @include sq-flex-box();
    }
} 

.search-results-form-wrapper.wrapper-left {
    width: 530px;
    float: left;
    margin: 0 0 30px 0;
}
.search-results-form-wrapper.wrapper-right {
    width: 300px;
    margin: 0 0 30px 30px;
    float: left;
}

.search-results-form-wrapper{
    #search-results-query {
        padding: 20px 15px;
    }
    #search-results-collection {
        padding: 20px 15px;
    }
}

.news-listing-form-wrapper {
    width: 400px;
    margin: 0 0 30px 0;
    float: left;
}


.main__aside {
    display: block;
    float: left;
    width: calc(30% - 46px);
    margin: 0 46px 0 0;
}
.main__content {
    display: block;
    float: left;
    width: 70%;
    padding: 0;
    table {
        width: 100%;
        overflow-x: auto;
        display: table;
    }
}
.main__content-wide {
    display: block;
    float: none;
    width: 100%;
}
.left-nav-border {
    position: relative;
    &:before {
        content: "";
        width: 3px;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -3px;
        background: $deep-orange;
    }
}
.main.layout_wide {
    .main__content {
        @extend .main__content-wide;
    }
    .main__aside {
        display: none;
    } 
}
.aside__navigation {
    display: block;
    @include ql-stripes(repeating-linear-gradient(
        -45deg, 
        #fff, 
        #f5f4f5 3px, 
        #f5f4f5 5px, 
        #f5f4f5 7px
    ));
}
.aside__navigation ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        position: relative;
        display: block;
    }
}
.aside__navigation__lvl-2 .aside_navigation__link {
    font-size: pxToRem(15);
    padding-left: 32px;
}
.aside_navigation__link {
    display: block;
    padding: pxToRem(15);
    font-size: pxToRem(17);
    color: #4f4f50;
    position: relative;
    border-bottom: 1px solid #f2f2f2;
    @include sq-transition(background 300ms);
    &:hover,
    &.focus {
        background: #fff;
        @extend .left-nav-border;
    }
}
.active > .aside_navigation__link {
    font-weight: bold;
    @extend .left-nav-border;
    background: #fff;
}

.navigation_has-subs {
    > .aside_navigation__link {
        padding-right: 40px;
    }
    &.active {
        > ul {
            @extend .left-nav-border;
            background: #fff;
        }
    }
}
.aside__navigation__lvl-3 {
    .aside_navigation__link {
        padding-left: 48px;
    }
}
.aside__navigation__toggler {
    position: absolute;
    top: 12px;
    right: 8px;
    left: auto;
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 5px 0 0 0;
    border: 1px solid transparent;
    @include sq-border-radius(50%);
    svg {
        @include sq-rotate(90);
    }
    &.active {
        svg {
            @include sq-rotate(-90);
        }
    }
    &:hover,
    &:focus {
        background: #fff;
        svg path {
            fill: $deep-orange;
        }
    }
}

.aside__navigation__lvl-hidden {
    @extend .visuallyhidden;
}
.navigation__sub-list-item.current {
    > .aside_navigation__link {
        overflow: hidden;
        color: #0f1b42;
        &:after {
            content: "";
            width: 12px;
            height: 12px;
            display: block;
            border-top: 12px solid transparent;
            border-right: 12px solid transparent;
            border-left: 12px solid $deep-orange;
            border-bottom: 12px solid transparent;
            position: absolute;
            left: -4px;
            top: 15px;
        }
    }
}

.aside__navigation__mobile-toggler {
    display: none;
}

/*---- Survey popup SDQLD-1528----*/
.survey-popup{
    width:34em;
}

.login-button {
    &__wrapper {
        // margin: pxToRem(10) 0;
    }

    // font-size: 16px;
}
/* Module: S */
body {
    font-family: $base-font;
    position: relative;
    z-index: 1;
    background: #f5f4f5;
    &.body-scroll-off {
        overflow-y: hidden;
    }
}

a {
    text-decoration: none;
    &:hover,
    &:focus,
    &:active {
        text-decoration: underline;
    }
}

/* Hide some elementa for mobile */
.site-links,
.navigation,
.search,
.header-mobile-options .search-item
 {
    display: none;
}

.header {
    display: block;
    position: relative;
    z-index: 9;
    background: #fff;
    a {
        text-decoration: none;
        &:hover, &:focus, &:active {
            text-decoration: none;
        }
    }
}

.logo {
    display: block;
    text-align: center;
    margin: pxToRem(20) 0 pxToRem(18) 0;
    a{
        display: block;
        text-align: center;
        img {
            display: block;
            margin: 0 auto;
        }
    }
}
.header-mobile-options {
    background: $deep-blue;
    padding: 0 pxToRem(17);
    ul {
        @extend %list-reset;
        @extend %clearfix;
        li {
            display: block;
            float: left;
        }
    }
    a {
        color: $base-white;
        text-transform: uppercase;
        display: block;
        padding: pxToRem(17) 0;
        @include sq-flex-box();
        span {
            line-height: pxToRem(16);
            padding: 0 0 0 pxToRem(7);
        }
    }
    .call-item,
    .login-item {
        float: right;
    }

    .login-item {
        a.login__toggler {
            display: inline-block;
            padding: 10px 0 0 12px;

            svg {
                display: inline-block;
                width: 35px;
                height: auto;
                fill: white;
            }
        }
    }
}


.footer {
    font-size: pxToRem(12);
    position: relative;
    z-index: 3;
    background: #fff;
    padding: 0 0 40px 0;
    .footer__list {
        margin: pxToRem(17) 0;
        @extend %list-reset;
        li {
            width: 49%;
            display: inline-block;
            text-align: center;
        }
    } 
    a {
        color: $dark-grey;
        display: block;
        padding: pxToRem(13) 0;
        font-weight: 600;
    }
    .copyright {
        color: $dark-grey;
        font-weight: 300;
        text-align: center;
    }
}

.navigation {
    .navigation__list {
        @extend %list-reset;
        @extend %clearfix;
    }
    li {
        display: block;
        float: left;
    }
}
.mobile-navigation {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    background-color: #002456;
    overflow-y: scroll;
    -webkit-transition: opacity 300ms ease-in, visibility 0s ease-in 300ms;
    transition: opacity 300ms ease-in, visibility 0s ease-in 300ms;
    &:after {
        content: "";
        height: 125px;
        width: 100%;
        bottom: 0;
        bottom: 0;
        position: fixed;
        z-index: -1;
        background: repeating-linear-gradient(
        60deg,
        #002456,
        #002456 38px,
        #0f3564 38px,
        #0f3564 76px
        );
        -webkit-box-shadow: inset 0px 50px 59px 0px rgba(0,36,86,1);
        -moz-box-shadow: inset 0px 50px 59px 0px rgba(0,36,86,1);
        box-shadow: inset 0px 50px 59px 0px rgba(0,36,86,1);
    }
    .search {
        display: block;
        margin: 13px 20px;
        .search-form {
            position: relative;
            .search-inner {
                @include sq-flex-box();
            }
            &:after {
                content: "";
                width: 100%;
                height: 100%;
                border: 1px solid #fff;
                @include sq-border-radius(50px);
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                position: absolute;
                display: block;
                z-index: -1;
                pointer-events: none;
                @include sq-opacity(50);
            }
        }
        .search-input {
            border: none;
            color: #fff;
            background: none;
            padding: 16px 20px 16px 28px;
            margin: 0;
            width: calc(100% - 45px);
            font-size: pxToRem(16);
            &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
                color:    #fff;
            }
            &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
               color:    #fff;
               opacity:  1;
            }
            &::-moz-placeholder { /* Mozilla Firefox 19+ */
               color:    #fff;
               opacity:  1;
            }
            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
               color:    #fff;
            }
            &::-ms-input-placeholder { /* Microsoft Edge */
               color:    #fff;
            }
            &::placeholder { /* Most modern browsers support this now. */
               color:    #fff;
            }
        }
        .search-submit {
            border: none;
            background: none;
            margin: 0;
            padding: 0 22px;
        }
    }
    .logo {
        background: $base-white;
        margin: 0;
        padding: pxToRem(20) 0 pxToRem(18) 0;
        float: none;
    }
    li > .header-mobile-options {
        display: none;
    }
    .header-mobile-options {
        position: relative;
        right: auto;
        margin: 0;
        color: #fff;
        svg path, svg g {
            fill: #fff;
        }
        .search-item {
            display: none;
        }
        .call-item {
            float: right;
        }
        .navigation-item {
            float: left;
        }
        .mobile-options__list-item {
            a {
                background: none;
                width: auto;
                margin: 0;
                border: none;
                border-radius: 0;
                float: left;
                padding: pxToRem(17) 0;
                height: auto;
                &:hover,
                &:focus,
                &:active {
                    background: none;
                }
                span {
                    display: inline-block;
                }
                svg {
                    width: auto;
                    height: auto;
                    padding: 0;
                    float: left;
                }
            }

            &.login-item {
                a.login__toggler {
                    display: inline-block;
                    padding: 10px 0 0 12px;
        
                    svg {
                        display: inline-block;
                        width: 30px;
                        fill: white;
                    }
                }
            }
        }
    }
    .site-links__list {
        @extend %list-reset;
        border-top: 1px solid #2b4b70;
        li {
            display: block;
            color: $base-white;
            position: relative;
            border-bottom: 1px solid #2b4b70;
            a {
                color: #fff;
                display: block;
                padding: pxToRem(10) pxToRem(50) pxToRem(10) pxToRem(32);
            }

            &.login-item {
                display: none;
            }
        }
        li:last-child {
            display: none;
        }
        li:nth-last-child(2) {
            border: none;
        }
    }
    .navigation__toggler {
        position: relative;
        &:after {
            content: "";
            width: 12px;
            height: 12px;
            display: block;
            background: none;
            border-top: 12px solid transparent;
            border-right: 12px solid transparent;
            border-left: 12px solid transparent;
            border-bottom: 12px solid #fff;
            position: absolute;
            bottom: -1px;
        }
    }
    .navigation__list {
        @extend %list-reset;
        border-top: 1px solid #fff;
        font-weight: bold;
        .navigation__list-item {
            display: block;
            color: $base-white;
            position: relative;
            .navigation__link {
                color: #fff;
                display: block;
                padding: pxToRem(10) pxToRem(50) pxToRem(10) pxToRem(32);
                &:hover,
                &:focus,
                &:active {
                    background: #2b4b70;
                    &:after {
                        @extend %left-hover-arrow;
                    }
                }
            }
            .navigation__sub-toggler {
                position: absolute;
                top: 0;
                right: 0;
                padding: 11px 0px 9px;
                width: 45px;
                text-align: center;
                @include sq-rotate(90);
                &.active {
                    @include sq-rotate(-90); 
                }
            }
            &.current > .navigation__link,
            .current > .navigation__link {
                background: #2b4b70;
                &:after {
                    @extend %left-hover-arrow;
                }
            }
        }
        > .navigation__list-item {
            font-size: pxToRem(17);
            text-transform: uppercase;
            border-bottom: 1px solid #fff;
        }
        .navigation__link {
            position: relative;
            &:before {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 4px;
                background: $deep-orange;
                z-index: 1;
            }
        }
        .theme__bc {
            .navigation__link {
                &:before {
                    background: $bc-green;
                }
                &:hover,
                &:focus,
                &:active {
                    &:after {
                        border-left: 12px solid $bc-green;
                    }
                }
            }
            &.current > .navigation__link:after,
            .current > .navigation__link:after {
                @extend %left-hover-arrow;
                border-left: 12px solid $bc-green;
            }
        }
        .theme__cc {
            .navigation__link {
                &:before {
                    background: $cc-blue;
                }
                &:hover,
                &:focus,
                &:active {
                    &:after {
                        border-left: 12px solid $cc-blue;
                    }
                }
            }
            &.current > .navigation__link:after,
            .current > .navigation__link:after {
                @extend %left-hover-arrow;
                border-left: 12px solid $cc-blue;
            }
        }
        .theme__cs {
            .navigation__link {
                &:before {
                    background: $light-orange;
                }
                &:hover,
                &:focus,
                &:active {
                    &:after {
                        border-left: 12px solid $light-orange;
                    }
                }
            }
            &.current > .navigation__link:after,
            .current > .navigation__link:after {
                @extend %left-hover-arrow;
                border-left: 12px solid $light-orange;
            }
        }
    }
    .navigation__sub-list {
        text-transform: none;
        @extend %list-reset;
        border-top: 1px solid #2b4b70;

    }
    .navigation__lvl-3 {
        font-weight: normal;
        font-size: pxToRem(16);
        border-top: none;
        li {
            position: relative;
            display: block;
            &:after {
                content: "";
                display: block;
                position: absolute;
                left: pxToRem(32);
                right: pxToRem(50);
                top: auto;
                bottom: 0;
                height: 1px;
                width: auto;
                background: #2b4b70;
            }
        }
    }
    .navigation__has-subs {
        overflow: hidden;
        > ul {
            height: 0;
            visibility: hidden;
        }
        &.active {
            overflow: visible; 
        }
        &.active > ul {
            height: auto;
            visibility: visible;
        }
    }

    .navigation__link-toggler {
        display: none;
    }
}
.active.mobile-navigation {
    visibility: visible;
    opacity: 1;
    width: 100%;
    height: 100%;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}
.navigation__list-item a {
    &:hover,
    &:focus {
        color: $deep-orange;
    }
}
.current > a {
    color: $deep-orange;
}

[class*="__toggler"] {
    .icon__open {
        display: block;
    }
    .icon__close {
        display: none;
    }
    &.active {
        > .icon__open {
            display: none;
        }
        > .icon__close {
            display: block;
        }

    }
}


.site-links {
    @extend %clearfix;
    background: $light-grey;
    ul {
        @extend %list-reset;
        display: block;
        float: right;
        li {
            display: inline-block;
        }
    }
    
}


/* Image banner */
.image-banner {
    display: block;
    width: 100%;
    position: relative;
    height: 235px;
    background-repeat: no-repeat;
    background-size: cover;
    img {
        width: 100%;
        display: none;
    }
}
.image-banner__container {
    overflow: hidden;
    position: relative;
    height: 0;
    @include sq-transition(opacity 1s);
    @include sq-opacity(0);
    &.active {
        display: block;
        height: 100%;
        @include sq-opacity(100);
    }
    &:after{
        content: "";
        width: 100%;
        height: 100%;
        background: #122754;
        @include sq-opacity(70);
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        position: absolute;
        display: block;
    }
}
.image-banner__info {
    position: absolute;
    z-index: 2;
    top: 50px;
    width: 100%;
    text-align: center;
    color: #fff;
    a {
        color: #fff;
    }
    &:before {
        content: "";
        display: block;
        position: absolute;
        top: -20px;
        left: 50%;
        margin-left: -52px;
        width: 104px;
        height: 4px;
        background: url(mysource_files/orange-angle-spacer.svg) repeat-x 0 0;
        background-size: 7px 4px;
    }
}
.inside-image-banner .image-banner__info {
    top: 33px;
}
.image-banner__bc .image-banner__info,
body.theme_bc .image-banner__info {
    &:before {
        background: url(mysource_files/green-angle-spacer.svg) repeat-x 0 0;
        background-size: 7px 4px;
    }
    .image-banner__cta:before {
        color: #8cc841;
    }
}
.image-banner__cc .image-banner__info,
body.theme_cc .image-banner__info {
    &:before {
        background: url(mysource_files/blue-angle-spacer.svg) repeat-x 0 0;
        background-size: 7px 4px;
    }
    .image-banner__cta:before {
        color: #00aae6;
    }
}

.image-banner__description {
    font-size: pxToRem(24);
    padding: 0 pxToRem(20);
}
.image-banner__cta {
    padding: 10px 0 0 0;
    display: block;
    &:before {
        content: "> ";
        color: $deep-orange;
        transform: rotate(90deg);
        display: inline-block;
        position: relative;
        margin: 0 10px 0 0;
    }

}

.home-tabs {
    display: block;
    position: relative;
    clear: both;
    @extend %clearfix;
}
.home-tabs__group {
    display: block;
    position: relative;
    background: $bc-green;
    color: #fff;
    padding: pxToRem(30) pxToRem(20);
    a {
        color: #fff;
    }
}
.home-tabs__title {
    font-size: pxToRem(21);
    margin: 0 0 pxToRem(15) 0;
    font-weight: bold;
    text-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
    width: 80%;
    a {
        display: block;
        padding: 0 45px 0 0;
        text-decoration: none;
    }
}
.home-tabs__description {
    font-size: pxToRem(15);
    margin: 0;
    width: 80%;
}
.home-tabs__image {
    display: none;
}
.home-tabs__bc {
    background: repeating-linear-gradient(
        60deg,
        #62B800,
        #62B800 38px,
        #63c300 38px,
        #63c300 76px
        );
        -webkit-box-shadow: inset 0px -50px 59px 0px rgba(98,184,0,1);
        -moz-box-shadow: inset 0px -50px 59px 0px rgba(98,184,0,1);
        box-shadow: inset 0px -50px 59px 0px rgba(98,184,0,1);
}
.home-tabs__toggler {
    display: block;
    position: absolute;
    width: 41px;
    height: 41px;
    background: #8cc841;
    padding: 9px;
    top: pxToRem(20);
    right: 25px;
    left: auto;
    text-decoration: none;
    cursor: pointer;
    @include sq-border-radius(50%);
    .icon__closed {
        display: none;
    }
}
.home-tabs__item {
    &.collapsed {
        .home-tabs__item-info {
            display: none;
        }
        .icon-block {
            @include sq-rotate(90);
        }
    }
    
}
.home-tabs__item-title {
    position: relative;
    margin: 10px 0;
    text-transform: uppercase;
    &:after {
        content: "";
        height: 100%;
        width: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        position: absolute;
        z-index: 0;
        background: #fff;
        @include sq-opacity(80);
    }
    a {
        display: block;
        position: relative;
        z-index: 2;
        font-size: pxToRem(18);
        color: #0f1b42;
        padding: 20px 50px 20px 23px;
    }
    .icon-block {
        display: block;
        @include sq-rotate(-90);
        width: 37px;
        height: 37px;
        padding: 9px 0 0 0;
        text-align: center;
        background: #fff;
        @include sq-border-radius(50%);
        position: absolute;
        top: 10px;
        right: 10px;
    }
}
.home-tabs__item-info {
    background: #fff;
    color: #0f1b42;
    padding: pxToRem(20);
    margin: -10px 0 0 0;
    .home-tabs__info-group {
        margin: 0 0 20px 0;
    }
    ul {
        list-style-image: url(mysource_files/green-chevron.svg);
        padding: 0 0 0 pxToRem(20);
        li {
            margin: 0 0 20px 0;
        }
    }
    a {
        color: #0f1b42;
    }
}
.home-tabs__info-group + .home-tabs__info-group {
    @include ql-bg-stripes('green');
    margin: 0 -10px 20px -10px;
    padding: 15px 10px 1px;
}
.home-tabs__info-group-title {
    text-transform: uppercase;
    font-size: pxToRem(18);
    margin: 0 0 15px 0;
}

.home-tabs__content {
    height: 0;
    overflow: hidden;
    visibility: hidden;
    width: 0;
    padding: 0;
    margin: 0;
}
.home-tabs__group.active {
    .home-tabs__content {
        height: 100%;
        overflow: visible;
        visibility: visible;
        width: 100%;
    }
    .home-tabs__description {
        display: none;
    }
    .icon__open {
        display: none;
    }
    .icon__closed {
        display: block;
    }
} 

/* General content related styling */
.button__rounded {
    position: relative;
    border: 1px solid #8cc841;
    @include sq-border-radius(20px);
    text-align: center;
    display: inline-block;
    background: #fff url(mysource_files/green-chevron.svg) no-repeat 10px 9px;
    padding: 5px 30px;
    &:hover {
        background-color: $light-grey;
    }
}
.home-tabs__bc {
    .home-tabs__info-group-title {
        color: $dark-lime-green;
    }
}

.home-tabs__cc {
    background: repeating-linear-gradient(
        120deg,
        #008cb2,
        #008cb2 38px,
        #0092ba 38px,
        #0092ba 76px
    );
    -webkit-box-shadow: inset 0px -50px 59px 0px rgba(0,140,178,1);
    -moz-box-shadow: inset 0px -50px 59px 0px rgba(0,140,178,1);
    box-shadow: inset 0px -50px 59px 0px rgba(0,140,178,1);
    .home-tabs__toggler {
        background: #00aae6;
    }
    .home-tabs__item-info ul {
        list-style-image: url(mysource_files/blue-chevron.svg);
    }
    .home-tabs__info-group + .home-tabs__info-group {
        @include ql-bg-stripes('blue');
    }
    .home-tabs__info-group-title {
        color: #008cb2;
    }
    .icon-block path {
        fill: #00AAE3;
    }
    .button__rounded {
        background: #fff url(mysource_files/blue-chevron.svg) no-repeat 10px 9px;
        border: 1px solid #008cb2;
    }
}

.home-tabs__cs {
    background: repeating-linear-gradient(
        60deg,
        #d64016,
        #d64016 38px,
        #ea5329 38px,
        #ea5329 76px
        );
    -webkit-box-shadow: inset 0px -50px 59px 0px rgba(234,83,41,1);
    -moz-box-shadow: inset 0px -50px 59px 0px rgba(234,83,41,1);
    box-shadow: inset 0px -50px 59px 0px rgba(234,83,41,1);
    .home-tabs__toggler {
        background: #ea5329;
    }
    .home-tabs__item-info ul {
        list-style-image: url(mysource_files/light-orange-chevron.svg);
    }
    .home-tabs__info-group + .home-tabs__info-group {
        @include ql-bg-stripes('blue');
    }
    .home-tabs__info-group-title {
        color: #ea5329;
    }
    .icon-block path {
        fill: #ea5329;
    }
    .button__rounded {
        background: #fff url(mysource_files/light-orange-chevron.svg) no-repeat 10px 9px;
        border: 1px solid #ea5329;
    }
}

.site-links,
[class*="navigation"],
.home-tabs__info-group-title,
.footer,
.aside__navigation {
    @extend .dinpro-normal;
}
.image-banner__info,
.home-tabs__title,
.home-tabs__item-title {
    @extend .din-alternate;
}



.ql-round-button {
    @include ql-round-button(#efefef, #ff1b15, #fff, #fff, 45px);
}

.ql__news {
    background: #efefef;
    display: block;
    padding: 24px 30px 42px 30px;
    margin: 1rem;
    position: relative;
}
.ql__news-title {
    font-weight: normal;
    font-size: pxToRem(18);
    text-transform: uppercase;
    margin: 0 0 30px 0;
    a {
        color: #e23c1f;
    }
}
.ql__news-item {
    color: #666;
    a {
        font-weight: bold;
        color: #001d45;
    }
    p {
        margin: 0;
    }
}
.ql__news-pagination {
    position: absolute;
    top: 18px;
    right: 30px;
    left: auto;
    display: block;
    overflow: hidden;
    > a {
        float: left;
    }
    .ql__news-pagination-count {
        float: left;
        margin: 5px 10px 0 10px;
    }

}
.ql__news-pagination-prev,
.ql__news-pagination-next {
    @include ql-round-button(#fff, #ff1b15, #001d45, #fff, 33px);
}
.ql__news-pagination-prev {
    @include sq-rotate(180);
}
.ql__news-pagination-count {
    color: #434343;
}
.ql__news-pagination-counter {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        display: none;
    }
    .slick-active {
        display: block;
    }
}
.main__aside {
    display: none;
}
.main {
    @extend .contain-width;
}


/* Themes */
.bc-body {
    .aside__navigation {
        .aside_navigation__link:hover:before, 
        .aside_navigation__link.focus:before, 
        .active > .aside_navigation__link:before, 
        .navigation_has-subs.active > ul:before {
            background: #8cc841;
        }
        .navigation__sub-list-item.current .aside_navigation__link:after {
            border-left-color: #8cc841;
        }
        .aside__navigation__toggler:hover svg path, 
        .aside__navigation__toggler:focus svg path {
            fill: #8cc841;
        }
    }
    
}
.cc-body {
    .aside__navigation {
        .aside_navigation__link:hover:before, 
        .aside_navigation__link.focus:before, 
        .active > .aside_navigation__link:before, 
        .navigation_has-subs.active > ul:before {
            background: #00AAE3;
        }
        .navigation__sub-list-item.current .aside_navigation__link:after {
            border-left-color: #00AAE3;
        }
        .aside__navigation__toggler:hover svg path, 
        .aside__navigation__toggler:focus svg path {
            fill: #00AAE3;
        }
    }
}


.image-banner__title {
    font-size: pxToRem(24);
}
.inside-image-banner .image-banner__description {
    font-size: 1rem;
}
.inside-image-banner .image-banner__info:before {
    top: 0;
}


.landing-grid {
    display: block;
    position: relative;
    width: 100%;
    padding: pxToRem(30);
    clear: both;
    overflow: hidden;
    @extend .contain-width;
}
.landing-grid__item {
    margin: 0 0 20px 0;
}
.landing-grid__link {
    display: block;
    width: 100%;
    height: 100%;
    background: #fff;
    padding: pxToRem(32) pxToRem(32) pxToRem(20);
    @include sq-transition(box-shadow 300ms);
    position: relative;
    .ql__icon {
        @include ql-round-button(#fff, #ff1b15, #ff1b15, #fff, 45px);
        position: absolute;
        right: 28px;
        bottom: 23px;
        border: 2px solid $deep-orange;
    }
    &:hover,
    &:active {
        text-decoration: none;
        @include sq-box-shadow(0 0 14px 0 rgba(0, 40, 85, 0.4));
        .ql__icon {
            @include ql-round-button(#ff1b15, #ff1b15, #fff, #fff, 45px);
        }
    }
}
.landing-grid__title {
    color: #011c43;
    font-size: pxToRem(20);
    @extend .dinpro-bold;
    margin: 0 0 20px 0;
}
.landing-grid__description {
    color: #434343;
    padding: 0 45px 45px 0;
}

.tab-navigation {
    display: none;
    @extend .dinpro-normal;
}
.image-banner + .tab-navigation {
    display: block;
    padding: pxToRem(30) pxToRem(30) 0;
    .tab-navigation-list {
        list-style: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        li {
            margin: 0 0 1px 0;
            width: 50%;
            float: left;
            display: block;
        }
        a {
            background: $deep-orange;
            color: #fff;
            margin: 0 1px 0 0;
            display: block;
            text-align: center;
            padding: 8px 5px;
            height: 100%;
            border-bottom: 3px solid transparent;
           
        }
    }
}

.tab-navigation-list {
    @extend .contain-width;
}



.i-want-to {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    padding: pxToRem(32) pxToRem(32) pxToRem(20);
    @include ql-bg-stripes('grey');
}
.i-want-to__title {
    color: $deep-orange;
    margin: 0 0 20px 0;
}
.i-want-to__list {
    list-style-image: url(mysource_files/orange-chevron.svg);
    padding: 0 0 0 pxToRem(20);
}
.i-want-to__item {
    margin: 0 0 20px 0;
}
.i-want-to__link {
    color: #0f1b42;
}
.quote-box {
    padding: pxToRem(32) pxToRem(50) pxToRem(16);
    @include ql-bg-stripes('grey');
}

table {
    margin: 0 0 30px 0;
    thead {
        th {
            border-top: 2px solid #e2e2e2;
            padding: 16px;
            font-weight: normal;
            color: #434343;
        }
    }
    td {
        padding: 16px;
    }
    tbody {
        tr:nth-child(odd){
            background: #fff;
        }
    }
    border-bottom: 2px solid $dark-slate-blue;
}


/* General content styles */
.main__content {
    padding: 20px;
    color: #434343;
    @extend .dinpro-normal;
    a {
        &:not(.i-want-to__link) {
            // color: #011c43;
            color: #0056b3;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
    h1, h2, h3, h4 {
        @extend .dinpro-normal;
    }
    h1 {
        font-weight: bold;
        font-size: pxToRem(37);
        color: #011c43;
        margin: 0 0 pxToRem(20) 0;
    }
    h2 {
        font-size: pxToRem(28);
        color: #011c43;
        margin: 0 0 pxToRem(20) 0;
    }
    h3 {
        font-size: pxToRem(20);
        color: #434343;
        text-transform: uppercase;
        margin: 0 0 pxToRem(20) 0;
    }
    h4 {
        font-size: pxToRem(17);
        color: #011c43;
        margin: 0 0 pxToRem(20) 0;
    }
    li {
        margin: 0 0 20px 0;
    }
    ul.arrow-list {
        list-style-image: url(mysource_files/dark-blue-chevron.svg);
        padding: 0 0 0 pxToRem(20);   
    }
    ol {
        li {
            margin: 0 0 20px 0;
            padding: 0 0 0 10px;
            position: relative;
            &:before {
                content: "";
                width: 28px;
                height: 28px;
                display: block;
                position: absolute;
                left: -27px;
                top: -3px;
                z-index: -1;
                background: #fff;
                @include sq-border-radius(50%);
            }
        }
    }
    ol ol {
        list-style-type: lower-alpha;
    }
    p {
        img {
            margin: 1rem;
        }
    }
    .intro-text {
        font-size: pxToRem(19);
        color: #e23c1f;
    }
    table {
        width: 100%;
        overflow-x: auto;
        display: block;
    }
}

.notifications {
    @extend .stripes__large-blue;
    position: relative;
    font-size: pxToRem(15);
    a {
        color: #fff;
    }
    * {
        color: #fff;
    }
}
.nav-active .notifications {
    display: none;
}
.notifications__inner {
    @extend .contain-width;
}
.notifications__item {
    position: relative;
    padding: 0 0 70px 0;
}
.notifications__close-item {
    position: absolute;
    right: 17px;
    top: auto;
    bottom: 16px;
}
.notifications__content {
    padding: 16px 17px 0 70px;
    p {
        margin: 0;
    }
}
.notifications__icon {
    position: absolute;
    top: 23px;
    left: 17px;
}
.notifications-pagination {
    position: absolute;
    display: block;
    overflow: hidden;
    color: #fff;
    padding: 0 17px 10px;
    bottom: 5px;
    > a {
        float: left;
    }
    .notifications-pagination-count {
        float: left;
        margin: 5px 10px 0 10px;
    }

}
.notifications-pagination-prev,
.notifications-pagination-next {
    @include ql-round-button(transparent, transparent, #fff, #fff, 33px);
    border: 1px solid rgba(255, 255, 255, 0.4);
    svg {
        padding: 6px 8px 6px 6px;
    }
}
.notifications-pagination-prev {
    @include sq-rotate(180);
}
.notifications-pagination-count {
    color: #434343;
}
.notifications-pagination-counter {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        display: none;
    }
    .slick-active {
        display: block;
    }
}

.content-accordion {
    display: block;
    width: 100%;
    clear: both;
    overflow: hidden;
    margin: 0 0 2rem 0;
}

.content-accordion-enabled {
    .content-accordion__content {
        height: 0;
        overflow: hidden;
        visibility: hidden;
        @include sq-transition(height 1s);
    }
    .content-accordion__item.active {
        .content-accordion__content {
            height: auto;
            overflow: visible;
            visibility: visible;
            margin: 1rem 0 0 0;
        }
    }
    
}
.content-accordion__item {
    position: relative;
    background: #fff;
    border-left: 3px solid $dark-slate-blue;
    padding: 20px 60px 20px 30px;
    margin: 0 0 6px 0;
    &.active {
        border-left: 3px solid $tomato;
        .content-accordion__toggler {
            svg {
                @include sq-transform(rotate(-90deg));
                path {
                    fill: $tomato;
                }
            }
        }
    }
    .content-accordion__title {
        margin: 0;
    }
}
.content-accordion__toggler {
    display: block;
    
    svg {
        position: absolute;
        top: 25px;
        right: 30px;
        @include sq-transform(rotate(90deg));
    }
    &:hover,
    &:focus {
        color: $tomato;
        svg {
            path {
                fill: $tomato;
            }
        }
    }
}
.content-tabs {
    display: block;
    width: 100%;
    clear: both;
    overflow: hidden; 
}
.content-tabs__item {
    height: 0;
    visibility: hidden;
    overflow: hidden;
    margin: 0;
    &.active {
        height: auto;
        visibility: visible;
        overflow: visible;
        margin: 0 0 30px 0;
    }
}
.content-tabs__list {
    list-style: none;
    margin: 0;
    padding: 0;
    border-bottom: 3px solid #fff;
    width: 100%;
    display: block;
    margin: 0 0 30px 0;
    li {
        display: inline-block;
        margin: 0;
        a {
            display: block;
            padding: 15px 30px;
            font-size: pxToRem(17);
            border-top: 2px solid transparent;
            &.active {
                background: #fff;
                border-top: 2px solid $tomato;
            }
        }
    }
}



/* Call to ACtion buttons */
body .cta-button {
    display: inline-block;
    color: #fff;
    text-transform: uppercase;
    padding: 11px 20px 11px 37px;
    @include sq-border-radius(50px);
    background: $dark-slate-blue url(mysource_files/chevron-white.svg) no-repeat 13px 15px;
    border: 1px solid $dark-slate-blue;
    font-weight: normal;
    margin: 0 10px 0 0;
    &:hover, &:focus {
        background-color: transparent;
        background-image: url(mysource_files/chevron-slate-blue.svg);
        color: $dark-slate-blue;
        text-decoration: none;
    }
    &.secondary {
        background-color: $tomato;
        border: 1px solid $tomato;
        &:hover, &:focus {
            background-color: transparent;
            background-image: url(mysource_files/chevron-tomato.svg);
        }
    }
    &.green {
        background-color: $dark-lime-green;
        border: 1px solid $dark-lime-green;
        &:hover, &:focus {
            background-color: transparent;
            background-image: url(mysource_files/chevron-dark-lime-green.svg);
        }
    }
    &.blue {
        background-color: $water-blue;
        border: 1px solid $water-blue;
        &:hover, &:focus {
            background-color: transparent;
            background-image: url(mysource_files/chevron-water-blue.svg);
        }
    }
}

.main form,
form[id*="page_account_manager"],
form[id*="page_asset_builder"],
form[id*="search_page"],
form[id*="form_email"],
form[id*="login_form"],
form[id*="page_password_reset"] {
    margin: pxToRem(20) 0 pxToRem(20) 0;
    .sq-form-question {
        margin: 0 0 40px 0;
    }
    fieldset {
        border: none;
        margin: 0;
        padding: 0;
    }
    legend {
        font-size: pxToRem(28);
        margin: pxToRem(20) 0 pxToRem(20) 0;
    }
    input,
    textarea {
        width: 100%;
        max-width: 100%;
        padding: 13px 15px;
        background: #fff;
        margin: 0;
        border: 1px solid $dark-slate-blue;
        color: $dark-slate-blue;
    }
    select::-ms-expand {
        display: none !important;
    }
    select {
        // Select boxes
        width: 100%;
        border: none;
        border-radius: 0;
        box-shadow: none;
        background-image: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        background-color: #fff;
        padding: 13px 15px;
        border: 1px solid $dark-slate-blue;
        color: $dark-slate-blue;
    }
    .sq-form-question-note {
        font-weight: bold;
        font-size: .85rem;
        display: block;
    }
    .sq-form-question-select .sq-form-question-answer {
        margin: 0;
        padding: 0;
        position: relative;
        &:after {
            content: "";
            display: block;
            position: absolute;
            right: 1rem;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 21px;
            height: 15px;
            background: url(mysource_files/scroll-arrow.svg) no-repeat 0 0;
            pointer-events: none;
        }
    }
    input,
    textarea,
    select {
        &.sq-form-error {
            border: 2px solid $tomato;
        }
    }

    label.sq-form-error {
        display: block;
        margin: 10px 0 0 0;
        padding: 10px 0 10px 50px;
        position: relative;
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 5px;
            left: 0;
            height: 31px;
            width: 43px;
            background: url(mysource_files/global-alert.svg) no-repeat 0 0;
        }
    }

        // Lists
    .sq-form-question-tickbox-list ul,
    .sq-form-question-option-list ul {
        @extend %list-reset;
    }
    .sq-form-question-option-list legend {
        margin-bottom: 0;
        font-size: 1rem;
    }
    .sq-form-question-tickbox-list {
        legend {
            font-size: 1rem;
        }
        li {
            overflow: hidden;
            display: block;
        }
    }
    input[type="radio"],
    input[type="checkbox"],
    input[type="radio"] ~ label,
    input[type="checkbox"] ~ label {
        float: left;
    }
    input[type="radio"] ~ label,
    input[type="checkbox"] ~ label {
        margin: 0 10px 0 0;
        padding: 5px 10px 0 0;
    }
    input[type="radio"],
    input[type="checkbox"] {
        margin: 0 pxToRem(6) 0 0;
    }
    input[type="checkbox"] {
        -webkit-appearance: none;
        background-color: #fff;
        border: 2px solid #c8c8c8;
        color: $tomato;
        box-shadow: none;
        padding: 4px;
        margin: 0 pxToRem(9) 0 0;
        height: 38px;
        width: 38px;
        display: block;
        float: left;
        position: relative;
        &:active, &:checked:active {
            background-color: #fff;
        }
        &:checked {
            background-color: #fff;
            border-color: $dark-slate-blue;
        }
        &:checked + span:before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            background: #fff;
            
        }
        &:checked:after {
            content: "";
            background: url('mysource_files/tick.svg') no-repeat 0 0;
            height: 18px;
            width: 24px;
            position: absolute;
            top: 8px;
            left: 6px;
            font-size: pxToRem(14);
        }
    }
    input[type="radio"] {
        -webkit-appearance: none;
        background-color: #fff;
        border: 2px solid #c8c8c8;
        color: red;
        box-shadow: none;
        padding: 4px;
        margin: 0 pxToRem(9) 0 0;
        height: 36px;
        width: 36px;
        display: block;
        float: left;
        position: relative;
        @include sq-border-radius(50%);
        &:active, &:checked:active {
            background-color: #fff;
        }
        &:checked {
            background-color: #fff;
            border-color: $dark-slate-blue;
        }
        &:checked + span:before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            background: #fff;
        }
        &:checked:after {
            content: "";
            height: 20px;
            width: 20px;
            position: absolute;
            top: 6px;
            left: 6px;
            @include sq-border-radius(50%);
            background: $tomato;
        }
    }
    input[type="submit"]{
        display: inline-block;
        float: right;
        background: $tomato url(mysource_files/chevron-white.svg) no-repeat 15px 12px;
        color: #fff;
        padding: 8px 20px 8px 40px;
        @include sq-border-radius(50px);
        border: none;
        width: auto;
        border: 1px solid transparent;
        &:hover,
        &:focus {
            color: $charcoal;
            border: 1px solid $tomato;
            background: #fff url(mysource_files/chevron-tomato.svg) no-repeat 15px 12px;
        }
        &[name*="previous_page"] {
            float: left;
            background: $charcoal url(mysource_files/chevron-white-reversed.svg) no-repeat 15px 12px;
            &:hover,
            &:focus {
                background: #fff url(mysource_files/chevron-tomato-reversed.svg) no-repeat 15px 12px;
            }
        }
    }
}

.fade-bg #page-wrapper > *:not(.header){
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
    @include sq-opacity(20);
}




body.theme_bc {
    /* $dark-lime-green */
    .navigation .navigation__lvl-1 > li.current > a,
    .i-want-to__title,
    .i-want-to__title,
    .main .main__content .intro-text
    {
        color: $dark-lime-green;
    }
    .tab-navigation-link:hover, 
    .tab-navigation-link:focus, 
    .tab-navigation-link.active {
        border-top-color: $dark-lime-green;
    }
    .navigation__sub-list-item.current > .aside_navigation__link:after {
        border-left-color: $dark-lime-green;
    }
    .i-want-to {
        @include ql-bg-stripes('green');
    }
    .i-want-to__list {
        list-style-image: url(mysource_files/green-chevron.svg);
    }
    .landing-grid__link .ql__icon {
        border-color: $dark-lime-green;
    }
    .landing-grid__link .ql__icon svg path, 
    .landing-grid__link .ql__icon svg g {
        fill: $dark-lime-green;
    }
    .landing-grid__link:hover .ql__icon svg path, 
    .landing-grid__link:hover .ql__icon svg g, 
    .landing-grid__link:active .ql__icon svg path, 
    .landing-grid__link:active .ql__icon svg g {
        fill: #fff;
    }
    .landing-grid__link:hover .ql__icon, 
    .landing-grid__link:active .ql__icon,
    .aside_navigation__link:hover:before, 
    .aside_navigation__link.focus:before, 
    .active > .aside_navigation__link:before, 
    .navigation_has-subs.active > ul:before {
        background: $dark-lime-green;
    }
    .image-banner + .tab-navigation a {
        background: $dark-lime-green;
    }
}

body.theme_cc {
    /* $water-blue */
    .navigation .navigation__lvl-1 > li.current > a,
    .i-want-to__title,
    .i-want-to__title,
    .main .main__content .intro-text
    {
        color: $water-blue;
    }
    .tab-navigation-link:hover, .tab-navigation-link:focus, .tab-navigation-link.active {
        border-top-color: $water-blue;
    }
    .navigation__sub-list-item.current > .aside_navigation__link:after {
        border-left-color: $water-blue;
    }
    .i-want-to {
        @include ql-bg-stripes('blue');
    }
    .i-want-to__list {
        list-style-image: url(mysource_files/blue-chevron.svg);
    }
    .landing-grid__link .ql__icon {
        border-color: $water-blue;
    }
    .landing-grid__link .ql__icon svg path, 
    .landing-grid__link .ql__icon svg g {
        fill: $water-blue;
    }
    .landing-grid__link:hover .ql__icon svg path, 
    .landing-grid__link:hover .ql__icon svg g, 
    .landing-grid__link:active .ql__icon svg path, 
    .landing-grid__link:active .ql__icon svg g {
        fill: #fff;
    }
    .landing-grid__link:hover .ql__icon, 
    .landing-grid__link:active .ql__icon,
    .aside_navigation__link:hover:before, 
    .aside_navigation__link.focus:before, 
    .active > .aside_navigation__link:before, 
    .navigation_has-subs.active > ul:before {
        background: $water-blue;
    }
    .image-banner + .tab-navigation a {
        background: $water-blue;
    }
}

body.theme_cs {
    /* $water-blue */
    .navigation .navigation__lvl-1 > li.current > a,
    .i-want-to__title,
    .i-want-to__title,
    .main .main__content .intro-text
    {
        color: $light-orange;
    }
    .tab-navigation-link:hover, .tab-navigation-link:focus, .tab-navigation-link.active {
        border-top-color: $light-orange;
    }
    .navigation__sub-list-item.current > .aside_navigation__link:after {
        border-left-color: $light-orange;
    }
    .i-want-to {
        @include ql-bg-stripes('orange');
    }
    .i-want-to__list {
        list-style-image: url(mysource_files/light-orange-chevron.svg);
    }
    .landing-grid__link .ql__icon {
        border-color: $light-orange;
    }
    .landing-grid__link .ql__icon svg path, 
    .landing-grid__link .ql__icon svg g {
        fill: $light-orange;
    }
    .landing-grid__link:hover .ql__icon svg path, 
    .landing-grid__link:hover .ql__icon svg g, 
    .landing-grid__link:active .ql__icon svg path, 
    .landing-grid__link:active .ql__icon svg g {
        fill: #fff;
    }
    .landing-grid__link:hover .ql__icon, 
    .landing-grid__link:active .ql__icon,
    .aside_navigation__link:hover:before, 
    .aside_navigation__link.focus:before, 
    .active > .aside_navigation__link:before, 
    .navigation_has-subs.active > ul:before {
        background: $light-orange;
    }
    .image-banner + .tab-navigation a {
        background: $light-orange;
    }
}


.search-results__content {
    .result-count__summary {
        font-size: 28px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 0.86;
        letter-spacing: normal;
        text-align: left;
        color: #002855;
    }
    .search-result {
        display: block;
        border-bottom: 1px solid #c8c8c8;
        margin: 0 0 20px 0;
        padding: 20px 0;
        .search-result__title {
            text-transform: none;
            margin: 0 0 10px 0;
            a {
                font-size: 20px;
                font-weight: normal;
                color: #2b4b70;
                display: block;
                clear: both;
                margin:  0 0 10px 0;
            }
            small {
                font-size: 16px;
                font-weight: bold;
                color: #555555;
                line-height: 1.5;
            }
        }
        p {
            color: #555555;
            font-size: 16px;
        }
    }
}

.search-results-form-wrapper {
    display: block;
    border: solid 1px #002855;
    background: #fff;
    margin: 0 0 5px 0;
    position: relative;
    #search-results-query {
        border: none;
        width: calc(100% - 55px);
        padding: 20px 15px;
    }
   .search-submit {
        border: none;
        background: none;
        padding: 18px 15px 11px;
        position: absolute;
        right: 0;
    }
    #search-results-collection {
        border: none;
    }
    &.wrapper-right {
        position: relative;
        &:after {
            content: "";
            display: block;
            position: absolute;
            right: 1rem;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 21px;
            height: 15px;
            background: url(mysource_files/scroll-arrow.svg) no-repeat 0 0;
            pointer-events: none;
        }
    }
}


.search-form {
    .twitter-typeahead {
        width: 100%;
    }
    .tt-triangle {
        display: none;
    }
    .tt-menu {
            width: 100vw;
            overflow: visible;
            visibility: visible;
            padding: 10px 25px 50px 25px;
            opacity: 1;
            left: -20px;
            top: 60px;
            min-height: 440px;
            &:before {
                content: "";
                display: block;
                position: absolute;
                background: #002855;
                z-index: -2;
                height: 100%;
                width: auto;
                left: 0;
                right: 0;
                top: 0;
            }
            &:after {
                content: "";
                height: 150px;
                width: auto;
                left: 0;
                right: 0;
                bottom: 0;
                display: block;
                position: absolute;
                z-index: -1;
                background: repeating-linear-gradient(
                60deg,
                #002855,
                #002855 38px,
                #0f3564 38px,
                #0f3564 76px
                );
                -webkit-box-shadow: inset 0 82px 82px #002855;
                -moz-box-shadow: inset 0 82px 82px #002855;
                box-shadow: inset 0 82px 82px #002855;
            }
            a {
                color: #fff;
            }
            .tt-dataset {
                display: block;
                //float: left;
                width: 100%;
                padding: 0 20px 0 20px;
                margin-bottom: 30px;
            }
            .tt-category {
                color: $tomato;
                font-size: 1.2rem;
                height: 2.7rem;
                position: relative;
                margin-bottom: 1.4rem;
                span {
                    position: absolute;
                    bottom: 0;
                }
            }
            .tt-dataset-bc .tt-category {
                color: $dark-lime-green;
            }
            .tt-dataset-cc .tt-category {
                color: $water-blue;
            }
            .tt-dataset-about .tt-category {
                color: $tomato;
            }
            .tt-suggestion {
                margin: 0.8rem 0;
                line-height: 1.2rem;
            }
    }
} 

.pagination__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    overflow: hidden;
    .pagination__list-item {
        display: block;
        float: left;
        font-size: pxToRem(20);
        margin: 0 15px 20px 0;
    }
    .pagination__link {
        display: block;
        width: pxToRem(32);
        height: pxToRem(32);
        line-height: pxToRem(32);
        text-align: center;
        border: solid 1px transparent;
        @include sq-border-radius(50%);
        text-decoration: none;
        &:hover, &:active {
            background: #c8c8c8;
            text-decoration: none;
        }
    }
    .pagination__current {
        border: solid 1px #002855;
        display: block;
        width: pxToRem(32);
        height: pxToRem(32);
        line-height: pxToRem(32);
        text-align: center;
        @include sq-border-radius(50%);
    }
    .pagination__previous {
        display: block;
        width: pxToRem(32);
        height: pxToRem(32);
        line-height: pxToRem(32);
        text-align: center;
        @include sq-transform(rotate(180deg));
    }
    .pagination__next {
        display: block;
        width: pxToRem(32);
        height: pxToRem(32);
        line-height: pxToRem(32);
        text-align: center;
    }
}


.news-listing {
    .news-item {
        display: block;
        border-bottom: 1px solid #c8c8c8;
        margin: 0 0 20px 0;
        padding: 20px 0;
        .news-item__title {
            text-transform: none;
            margin: 0 0 10px 0;
            a {
                font-size: 20px;
                font-weight: normal;
                color: #2b4b70;
                display: block;
                clear: both;
                margin: 0 0 10px 0;
            }
        }
        .news-item__date {
            font-size: 0.8rem;
            margin-bottom: 1.5rem;
        }
    }
}

.news-listing-form-wrapper {
    display: block;
    border: solid 1px #002855;
    background: #fff;
    margin: 0 0 5px 0;
    position: relative;
    &:after {
        content: "";
        display: block;
        position: absolute;
        right: 1rem;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 21px;
        height: 15px;
        background: url(mysource_files/scroll-arrow.svg) no-repeat 0 0;
        pointer-events: none;
    }
}



$base-blue: #3652a3; // denim blue
$secondary-blue: #1d3373; // cobalt blue
$base-light-blue: #0098d6; // cerulean
$base-orange: #f8991d; // squash
$base-orange-rgba: rgba(248, 153, 29, 0.9);
$base-green: #6db277; // tea
$base-white: #fff;
$base-white-rgba: rgba(255, 255, 255, 0.9);
$base-grey: #919dbe; // bluegrey
$base-background: #f6f6f9;
$base-black: #000;
$base-red: red;

.docs {
    
    .docs__section {
        margin: 0 0 25px 0;
        border: 1px dashed #fff;
        padding: 25px;
    }
    .docs__asset-id {
        background: $base-light-blue;
        padding: 5px 10px;
        position: relative;
        color: #fff;
        margin: 0 0 5px 0;
        &:before {
            content: "Asset ID: ";
            font-weight: bold;
        }
    }
    
    .docs__editing-type {
        background: $base-orange;
        padding: 5px 10px;
        position: relative;
        color: #fff;
        margin: 0 0 5px 0;
        &:before {
            content: "Config: ";
            font-weight: bold;
            display: block;
        }
        &.matrix-admin:before {
            content: "Admin notes: ";
            display: block;
        }
    }
    .docs__metadata {
        background: $base-green;
        padding: 5px 10px;
        position: relative;
        color: #fff;
        margin: 0 0 5px 0;
        &:before {
            content: "Metadata: ";
            font-weight: bold;
            display: block;
        }
    }
    .docs__image-size {
        background: $base-green;
        padding: 5px 10px;
        position: relative;
        color: #fff;
        margin: 0 0 5px 0;
        &:before {
            content: "Optimal image size: ";
            font-weight: bold;
            display: block;
        }
    }
    .docs__cct {
        background: $base-green;
        padding: 5px 10px;
        position: relative;
        color: #fff;
        margin: 0 0 5px 0;
        &:before {
            content: "Content container template name: ";
            font-weight: bold;
            display: block;
        }
    }
    .docs__useage {
        display: block;
        margin: 0 0 20px 0;
        &:before {
            content: "Details: ";
            font-weight: bold;
            display: block;
            background: #fff;
            padding: 5px 10px;
        }
        img {
            height: auto;
        }
    }
    
    .docs__images {
        text-align: center;
        display: block;
        text-align: center;
        margin: 25px 0 0;
        img {
            max-width: 100%;
            border: 25px solid #fff;
        }
        a {
            display: block;
            position: relative;
            margin: 0 0 25px 0;
            background: #fff;
            border: 1px solid grey;
            &:hover::before {
                content: attr(title);
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                background: $base-green;
                padding: 5px 10px;
                color: #fff;
           }
        }
        .docs__image-ref {
            display: block;
            text-align: center;
            background: grey;
            color: #fff;
            clear: both;
            padding: 5px 0;
        }
    }
}






.main__aside {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    background: #fff;
    bottom: 0;
    z-index: 98;
    left: -100%;
    @include sq-transition(300ms left);
    &.active {
        left: 0;
    }
    .aside__navigation__mobile-toggler {
        display: block;
        padding: 15px 15px;
    }
}
.aside__navigation.active {
    display: block;
    @include ql-stripes(repeating-linear-gradient(
        -45deg, 
        #fff, 
        #f5f4f5 3px, 
        #f5f4f5 5px, 
        #f5f4f5 7px
    ));
}
.left-nav-border-mob {
    position: relative;
    &:before {
        content: "";
        width: 3px;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -3px;
        background: $deep-orange;
    }
}
.aside__navigation ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        position: relative;
        display: block;
    }
}
.aside__navigation__lvl-2 .aside_navigation__link {
    font-size: pxToRem(15);
    padding-left: 32px;
}
.aside_navigation__link {
    display: block;
    padding: pxToRem(15);
    font-size: pxToRem(17);
    color: #4f4f50;
    position: relative;
    border-bottom: 1px solid #f2f2f2;
    @include sq-transition(background 300ms);
    &:hover,
    &.focus {
        background: #fff;
        @extend .left-nav-border-mob;
    }
}
.active > .aside_navigation__link {
    font-weight: bold;
    @extend .left-nav-border-mob;
    background: #fff;
}

.navigation_has-subs {
    > .aside_navigation__link {
        padding-right: 40px;
    }
    &.active {
        > ul {
            @extend .left-nav-border-mob;
            background: #fff;
        }
    }
}
.aside__navigation__lvl-3 {
    .aside_navigation__link {
        padding-left: 48px;
    }
}
.aside__navigation__toggler {
    position: absolute;
    top: 12px;
    right: 8px;
    left: auto;
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 5px 0 0 0;
    border: 1px solid transparent;
    @include sq-border-radius(50%);
    svg {
        @include sq-rotate(90);
    }
    &.active {
        svg {
            @include sq-rotate(-90);
        }
    }
    &:hover,
    &:focus {
        background: #fff;
        svg path {
            fill: $deep-orange;
        }
    }
}
.aside__navigation__lvl-hidden {
    @extend .visuallyhidden;
}
.navigation__sub-list-item.current {
    > .aside_navigation__link {
        overflow: hidden;
        color: #0f1b42;
        &:after {
            content: "";
            width: 12px;
            height: 12px;
            display: block;
            border-top: 12px solid transparent;
            border-right: 12px solid transparent;
            border-left: 12px solid $deep-orange;
            border-bottom: 12px solid transparent;
            position: absolute;
            left: -4px;
            top: 15px;
        }
    }
}

#levy-calculator_price-table {
    tbody {
        td {
            display: block;
            width: 100%;
            clear: both;
            padding:  1rem 1rem 0 1rem;
        }
        tr td + td {
            padding:  1rem;
        }
    }
}

.video-frame-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0;
    height: 0;
    display: block;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}


.qleave-cci-portal {
    a.btn {
        color: #fff;
    }
}

/*---- Survey popup SDQLD-1528----*/
.survey-popup{
    position: fixed;
    bottom: 2em;
    width: 100%;
    padding: 1em 2em;
    background: #f8f9fa;
    right: 0;
    box-shadow: 0 0 14px 0 rgba(0, 40, 85, 0.4);    
    display:none;
    height:24.4em;
}
.survey-popup__container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.survey-popup__container form{
    width: 100%;
}
.survey-popup.active{
     display: block;
 }
.survey-popup__close-item .icon__close{
    height: 34px;
    width: 34px;
    background: #ea5329;
    position: absolute;
    right: 2em;
    border-radius: 50%;
    padding: .5em;
    bottom: 1em;
}
.survey-popup form input[type="submit"].sq-form-submit{
    float: left;
}
.survey-popup .survey-popup__close-item{
    display: inline;
}
.survey-popup form .sq-form-question{
    margin: 0 0 20px 0;
}
.survey-popup form .sq-form-section-title{
    margin: 0;
}

.login-popup {
    display: none;
    top: 20vh;
    min-height: 40vh;
    max-height: 80vh;
    width: calc(100% - 1.875rem);
    height: auto;
    background: #fff;
    max-width: 40rem;
    overflow: auto;
    left: 50%;
    transform: translateX(-50%);
    position: fixed;
    z-index: 20;
    padding: 16px;
    box-shadow: 0 4px 12px 0 rgba(#000, 0.15);

    &.active {
        display: block;
    }

    &__close {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 1.5rem;

        & > button {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            cursor: pointer;
            border: 0;
            background: none;

            svg g {
                fill: #000;
            }
        }
    }

    &__wrapper {
        a.button {
            padding: 0.5em 1em;
            color: #fff;
        }

        p {
            text-align: center;

            a {
                justify-content: center;
                width: 100%;
            }
        }
    }

    &__bg {
        background-color: #000;
        opacity: 0.5;
        left: 0;
        top: 0;
        position: fixed;
        height: 100vh;
        width: 100vw;
        z-index: 8;
    }
}

.login-button {

    &__wrapper {
        // margin: pxToRem(16) 0;
    }

    color: $base-white;
    text-decoration: none;
    border: 1px solid $light-grey;
    padding: pxToRem(4) pxToRem(16);
    display: inline-block;
    border-radius: 20px;
    background-color: transparent;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}
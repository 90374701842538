/**
 * author:  Squiz Australia
 *
 * Table of Contents
 *
 * - Reset
 * - Utilities
 * - General
 * - Content
 * - Modules
    {{toc}}
 */
 @font-face {
    font-family: 'DINPro';
    src: url('mysource_files/DINPro-Regular.woff2') format('woff2'),
        url('mysource_files/DINPro-Regular.woff') format('woff'),
        url('mysource_files/DINPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
 @font-face {
    font-family: 'DINPro';
    src: url('mysource_files/DINPro-Light.woff2') format('woff2'),
        url('mysource_files/DINPro-Light.woff') format('woff'),
        url('mysource_files/DINPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'DINPro';
    src: url('mysource_files/DINPro-Bold.woff2') format('woff2'),
        url('mysource_files/DINPro-Bold.woff') format('woff'),
        url('mysource_files/DINPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'DINAlternate';
    src: url('mysource_files/DIN-RegularAlternate.woff2') format('woff2'),
        url('mysource_files/DIN-RegularAlternate.woff') format('woff'),
        url('mysource_files/DIN-RegularAlternate.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

// These imports have no CSS output, but provide variables, mixins and helpers to aid in construction of
// a design cutup.
@import "boilerplate.scss";

/*
--------------------
Reset (normalize.css)
--------------------
*/

@import "normalize.scss";

// This import disabled by default due to rendering issues, particularly with IE where some text doesn't render at all
//@import "normalize-opentype.scss";

// Box size all the things
// https://squizmap.squiz.net/imp-bp/6400
// http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
// == Start box-sizing reset ==
*, *:before, *:after {
    -webkit-box-sizing: inherit;
       -moz-box-sizing: inherit;
            box-sizing: inherit;
}
html {
    font-size: 1em !important;
    @include sq-border-box;
}
// == End box-sizing reset ==

/*
--------------------
Utilities
--------------------
*/

@import "utilities.scss";

/*
--------------------
General
--------------------
*/

// Taken from HTML 5 BP
::-moz-selection {
    background: $content-selection-highlight;
    text-shadow: none;
}

::selection {
    background: $content-selection-highlight;
    text-shadow: none;
}

html,
body {
    height: 100%;
}
body {
    overflow-y: scroll;
    font-size: $base-font-scale + em;
    line-height: 1.4;
    padding: 0;
    margin: 0;
}

img {
    max-width: 100%;
}

// Reset fonts for relevant elements (taken from bootstrap)
input,
button,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

// IE10 Metro responsive
// Required for Windows 8 Metro split-screen snapping with IE10
// Source: http://timkadlec.com/2012/10/ie10-snap-mode-and-responsive-design/
@-ms-viewport{
    width: device-width;
}

// Print style that shouldn't belong to print.scss to avoid the css beautifier from killing
// the @ syntax inside media queries
@page {
    margin: 0.5cm;
}

/*
--------------------
Content
--------------------
*/

// You can wrap the content styles in a class by un-commenting the following lines
// to nest the content.scss styles inside .content
//.content {
    @import "content.scss";
//}


/*
--------------------
Modules
--------------------
*/
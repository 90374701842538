// Base font
$base-font: 'DINPro', Helvetica, Verdana, sans-serif;
$font-dinpro: 'DINPro', Helvetica, Verdana, sans-serif;
$font-dinalternate: 'DINAlternate', Helvetica, Verdana, sans-serif;

/* DIN font application */
.dinpro-light {
  font-family: $font-dinpro;
  font-weight: 300;
}
.dinpro-normal {
  font-family: $font-dinpro;
}
.dinpro-bold {
  font-family: $font-dinpro;
  font-weight: bold;
}
.din-alternate {
  font-family: $font-dinalternate;
}

$base-font-scale: 1;  // The font scale multiplier (e.g. 2 = 32px/2em/2rem)
$base-font-pixel: 16; // Used for font size calculations & conversions

// COLOUR schemes
$base-font-colour: #434343;
$base-white: #fff;
$deep-blue: #001b44;
$deep-orange: #ff1b15;
$dark-grey: #555;
$light-grey: #f2f2f2;
$light-green: #8cc841;
$light-blue: #00aae6;
$light-orange: #ea5329;
$navy-blue: #002855;



$dark-lime-green: #7db90a;
$apple: #8cc841;
$news-light-grey: #efefef;
$dark-slate-blue: #122754;
$tomato: #ea5329;
$bg-light-grey: #f6f6f6;
$azure: #00aae6;
$water-blue: #0b90b7;
$top-bar-grey: #f2f2f2;
$charcoal: #434343;


$bc-green: $dark-lime-green;
$cc-blue: $water-blue;


// Selection highlighting
$content-selection-highlight: #b3d4fc;



.stripes__large-blue {
  &:before {
      content: "";
      height: 100%;
      width: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      position: absolute;
      z-index: -2;
      background-color: #002456;
  }
  &:after {
      content: "";
      height: 100%;
      width: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      position: absolute;
      z-index: -1;
      background: repeating-linear-gradient(
      60deg,
      #002456,
      #002456 38px,
      #0f3564 38px,
      #0f3564 76px
      );
  }
}
.stripes__large-blue-fadeout {
  &:after {
      -webkit-box-shadow: inset 0px 50px 59px 0px rgba(0,36,86,1);
      -moz-box-shadow: inset 0px 50px 59px 0px rgba(0,36,86,1);
      box-shadow: inset 0px 50px 59px 0px rgba(0,36,86,1);
  }
}
.stripes__thin-grey {
  background: repeating-linear-gradient(
      45deg,
      #fff,
      #fff 1px,
      #f5f4f5 2px,
      #f5f4f5 5px
  );
}
.stripes__thin-blue {
  background: repeating-linear-gradient(
      45deg,
      #CCE9F1,
      #CCE9F1 1px,
      #fff 2px,
      #fff 5px
  );
}
.stripes__thin-green {
  background: repeating-linear-gradient(
        45deg,
        #E8F7D9,
        #E8F7D9 1px,
        #fff 2px,
        #fff 5px
    );
}